<template>
  <div>
    <van-sticky>
      <van-nav-bar right-text="分享"
                   @click-right="onClickShare">
        <template #title>
          <span style="font-weight:600;color:#0af">GOLANG ROADMAP·Go课程</span>
        </template>
        <template #left>
          <van-image radius="18px"
                     height="40px"
                     width="40px"
                     @click="txClick"
                     src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
        </template>
      </van-nav-bar>
      <!-- 头部轮播图 -->
      <!-- <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item, index) in images"
                      :key="index">
        <div @click="goto(item.url)">
          <van-image radius="5px"
                     :src="item.image"
                     alt="" />
        </div>
      </van-swipe-item>
    </van-swipe> -->

      <!-- Go视界分类切换 -->
      <van-tabs @click="onClick">
        <van-tab v-for="(item, index) in category"
                 :key="index">

          <template #title>

            <div class="category-item">
              <span>
                {{ item.value }}
              </span>
              <div>
                {{ item.label }}
              </div>

            </div>

          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-loading style="padding: 90px; 0"
                 v-if="loading"
                 color="#0094ff"
                 size="24px"
                 vertical>加载中...</van-loading>
    <van-empty description="欢迎投稿丰富此版块"
               v-if="list.length  === 0 && loading === false" />

    <van-card v-for="(item, index) in fmtData"
              :key="index"
              :origin-price="item.oldPrice"
              :price="item.price"
              :thumb="item.img">
      <template #title>

        <span class="card_title">{{ item.name }}</span>

      </template>
      <template #tags>
        <van-tag plain
                 type="danger">{{ item.tag }}</van-tag>
      </template>
      <template #desc>

        <!-- 最多显示三行 -->
        <div class="card_desc"
             v-html="item.memo">

        </div>

      </template>
      <template #footer>
        <van-button type="info"
                    size="mini"
                    :url="item.url">课程详情</van-button>
        <van-button type="info"
                    @click="buyClick(item.kttImg)"
                    size="mini">购买课程</van-button>
      </template>

    </van-card>

    <van-dialog v-model="popupShow"
                title="微信扫码下单"
                show-cancel-button>
      <div style="text-align:center;">
        <img style="width:280px"
             :src="buyImg" />
      </div>

    </van-dialog>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { Image as VanImage, Sticky, NavBar, ImagePreview, ShareSheet, Dialog, Button, Card, Loading, Tabbar, TabbarItem, Cell, CellGroup, Popup, Pagination, Grid, GridItem, Swipe, SwipeItem, Col, Row, Icon, Tag, Tab, Tabs, Empty } from 'vant';

const axios = require("axios");
export default {
  components: {
    [VanImage.name]: VanImage,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    [Card.name]: Card,
    [Loading.name]: Loading,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Pagination.name]: Pagination,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data () {
    return {
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
      ],
      //列表加载效果开关
      loading: false,
      // Go视界分类
      category: [
        { label: "全部", value: "🔵" },
        { label: "Go基础", value: "📚" },
        { label: "Go实战", value: "⚔️" },
        { label: "云原生", value: "☁️" },
        { label: "其他", value: "🛸" },
      ],
      //头部轮播图
      images: [
        { image: 'https://image-1302243118.cos.ap-beijing.myqcloud.com/img/gmstjz_sy_banner.jpg', url: "https://www.golangroadmap.com/docs/books/interviewPresentation.html" },
        { image: 'https://image-1302243118.cos.ap-beijing.myqcloud.com/img/qzfu_sy_banner.jpg', url: "https://www.golangroadmap.com/docs/offer/resume.html" },
      ],
      //Go课程全部数据
      list: [],
      //展示数据
      fmtData: [],
      popupShow: false,
      buyImg: '',
      showShare: false

    };
  },
  created () {
    //设置邀请码
    if (this.$route.query.code !== undefined) {
      window.localStorage.setItem('code', this.$route.query.code)
    }
    this.initData()
  },
  methods: {
    //点击头像进入服务页
    txClick () {
      this.$router.push('user');
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
    //分享
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/class.jpg'
        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: 'Go课程已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = "Go课程专区" + ": https://m.golangroadmap.com/#/class" + "?code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //导航切换
    onClick (name) {

      this.fmtData = this.list.filter(item => {
        if (name === 0) {
          return item
        } else if (item.feature.includes(this.category[name].label)) {
          return item
        }
      })
    },

    initData () {
      this.list = [];
      this.loading = true;
      axios
        .post("https://api.golangroadmap.com/getPracticalCourse")
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data
            this.fmtData = response.data.data
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    buyClick (val) {
      this.buyImg = val
      this.popupShow = true
    },
    goto (val) {
      window.open(val, '_blank');
    }
  }
};
</script>

<style>
.card_desc {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 2px;
}
/* .van-tabs__line {
  background-color: #39a9ed;
} */

.my-swipe .van-swipe-item {
  color: #fff;
  width: 300px;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.blog-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 140px;
  height: 210px;
  margin-right: 8px;
}
.card_title {
  font-size: 14px;
  line-height: 25px;
  color: var(--juejin-font-2);
  margin-top: 4px;
}
</style>